import React from "react";
import ReactMarkdown from "react-markdown";

const PrivacyPolicyInner = () => {
  return (
    <>
      <div className="privacy-policy-inner">
        <div className="generic-text__content">
          <ReactMarkdown children={
              "**Privacy Policy**\n\n" +
              "Sean Blahovici built the Itinerate app as a Freemium app. This SERVICE is provided by Sean Blahovici at no cost and is intended for use as is.\n" +
              "This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service." +
              "If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used " +
              "for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy. " +
              "The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible on this website unless otherwise defined in this Privacy Policy.\n\n" +
              "**Information Collection and Use**\n\n" +
              "For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but not limited to email, name & app usage. The information that I request will be retained on your device as well as on the private Google Firebase servers. Firebase services encrypt data in transit using HTTPS and logically isolate customer data. For more information about firebase policy, please check Firebase's privacy policy below.\n" +
              "All of your trip data is also persisted to Google Firebase, in order to provide multi-device functionality. Any uploaded files are accessible to the developers and could technically be accessed. The people working on Itinerate value your privacy and will not consult any private information without prior consent.\n" +
              "The app does use third-party services that may collect information used to identify you.\n" +
              "Here are the links to the privacy policies of third-party service providers used by the app:\n\n" +
              "[Firebase](https://firebase.google.com/support/privacy)  \n" +
              "[Google Play Services](https://policies.google.com/privacy)  \n" +
              "[Google Analytics for Firebase](https://firebase.google.com/policies/analytics)  \n" +
              "[Facebook](https://www.facebook.com/about/privacy/update/printable)  \n" +
              "[Amplitude](https://amplitude.com/privacy)  \n" +
              "[Open AI API](https://openai.com/enterprise-privacy/)  \n" +
              "[Mixpanel](https://mixpanel.com/legal/privacy-policy/)\n\n" +
              "**Log Data**\n\n" +
              "I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.\n\n" +
              "**Cookies**\n\n" +
              "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory." +
              "This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.\n\n" +
              "**Service Providers**\n\n" +
              "I may employ third-party companies and individuals due to the following reasons:  \n" +
              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; * To facilitate our Service;  \n" +
              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; * To provide the Service on our behalf;  \n" +
              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; * To perform Service-related services; or  \n" +
              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *  To assist us in analyzing how our Service is used.  \n" +
              "I want to inform users of this Service that these third parties have access to your Personal Information. " +
              "The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.\n\n" +
              "**About forwarding reservations to Itinerate - versions 8.0 and above only**\n\n" +
              "It is now possible to forward email reservations to a custom generated email address. This will import the reservation directly into the target trip.\n" +
              "Itinerate only stores the reservation data within the trip data. However, in some cases, I require the use of an artificial intelligence API to extract the reservation information.\n" +
              "Itinerate uses the OpenAI api for reservation extraction. The user data is sent through an API request to OpenAI. According to their privacy policy, they do not train their models with API request input data. They only maintain data on their servers for 30 days. For more information, read the privacy policy linked above.\n\n" +
              "**About importing content from user Gmail accounts - versions 7.4.1 and below only**\n\n" +
              "You can now import reservations directly from Gmail, upon request. To use this feature, you must login with your Google account and grant read-only access to your Gmail messages. \n" +
              "Itinerate will only read emails between two dates that you specify. Reservations do not sync automatically. You must synchronize manually every time you want to import new reservations. \n" +
              "Itinerate only reads standard embedded email markup formatted by businesses. Absolutely no personal content will be stored in any way. The only content that will be stored in our secure Firebase servers is the markup data and/or reservation data added by businesses in their respective emails. This data will be used to augment the travel planning experience, and allow users to place their reservations where they choose in their trip timeline and/or destination pin board.\n\n" +
              "**About translated results - disclaimer**\n\n" +
              "This service may contain translations powered by Google. Google disclaims all warranties related to the translations, express or implied, including any warranties of accuracy, reliability, and any implied warranties of merchantability, fitness for a particular purpose and noninfringement.  \n" +
              "Some app results may be translated for your convenience using translation software powered by Google Translate. Reasonable efforts have been made to provide an accurate translation, however, no automated translation is perfect nor is it intended to replace human translators. Translations are provided as a service to users of Itinerate, and are provided as is. No warranty of any kind, either expressed or implied, is made as to the accuracy, reliability, or correctness of any translations made from english into any other language. Some content may not be accurately translated due to the limitations of the translation software.  \n" +
              "The official text is the English version of the app. Any discrepancies or differences created in the translation are not binding and have no legal effect for compliance or enforcement purposes. If any questions arise related to the accuracy of the information contained in the translated website, refer to the english version of the website which is the official version.\n\n" +
              "**Limited use requirement**\n\n" +
              "Itinerate's use and transfer to any other app of information received from Google APIs will adhere to the [Google API Services User Data Policy](https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes), including the Limited Use requirements.\n\n" +
              "**Security**\n\n" +
              "I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.\n\n" +
              "**Links to other sites**\n\n" +
              "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.\n\n" +
              "**Children’s Privacy**\n\n" +
              "These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do the necessary actions.\n\n" +
              "**Requesting data deletion**\n\n" +
              "At any time, you may contact the developer (Sean Blahovici) by sending an email to application.craftsman@gmail.com. The developer will delete all data associated with you and your account and can provide video proof as needed.\n\n" +
              "**Advertisements**\n\n" +
              "In our app, we display advertisements mediated through Applovin Max, which incorporates networks such as Google AdMob, Meta Audience Network, AppLovin, and InMobi. These platforms help serve relevant and tailored ads to enhance your experience. Here's how:\n\n" +
              "1. *Data Collected*: The advertising platforms, including AdMob, Meta Audience Network, AppLovin, and InMobi, may collect information such as your device's advertising identifier, IP address, and device information. This data helps in delivering targeted and pertinent ads to improve your app experience.\n\n" +
              "2. *Usage*: The collected data ensures that the ads displayed are relevant to your interests, facilitates reporting to advertisers, and avoids showing you repetitive ads.\n\n" +
              "3. *Your Choices*: If you prefer, you can opt-out of personalized advertising by adjusting your device's ad settings. If you'd rather not see any ads, we offer an option to purchase an in-app product to remove the banner ads.\n\n" +
              "4. *Third-Party Policies*: Each advertising platform has its own set of policies and principles to ensure a positive user experience. Please consult the privacy policy of each advertising platform individually for more information.\n\n" +
              "**In-App Purchases**\n\n" +
              "Our app offers in-app purchases. Specifically, users can purchase an option to remove banner ads:\n\n" +
              "1. *Transaction Data*: When you make an in-app purchase, certain transaction details may be accessible to us such as your purchase date and the item purchased. However, we do not handle or store payment information like credit card numbers. Payment transactions are processed through the respective app platform's payment system (e.g., Google Play).\n\n" +
              "2. *Usage of Purchase Information*: We use your purchase information to provide you with the purchased service (i.e., to remove banner ads) and for internal analytics to improve our app services.\n\n" +
              "**Changes to This Privacy Policy**\n\n" +
              "I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.\n\n" +
              "This policy is effective as of 2023-30-09\n\n" +
              "**Contact Me**\n\n" +
              "If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at application.craftsman@gmail.com.\n\n"
          }
        />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyInner;
